<template>
    <c-panel v-if="orders">
        <c-grid class="widths-100-all">
            <!-- <c-grid-item class="mb-2">
                <c-grid>
                    <c-grid-item>
                        TODO: Search & Filter Controls
                    </c-grid-item>
                </c-grid>
            </c-grid-item> -->
            <template v-for="order in orders" :key="order.id">
                <c-grid-item>
                    <c-card
                        v-if="order.data && order.exists"
                        class="order pr-3"
                        :class="order.data.status?.toLowerCase()"
                        @click="$router.push('/admin/orders/' + order.id)"
                    >
                        <c-grid class="f-align-center spacing-sm-all">
                            <c-grid-item>
                                <div class="status"></div>
                            </c-grid-item>
                            <c-grid-item class="width-grow-all text-bold">
                                {{ order.data.shipping.address.name ?? order.id }}
                            </c-grid-item>
                            <c-grid-item class="text-bold"> ${{ order.data?.invoice?.total }} </c-grid-item>
                        </c-grid>
                    </c-card>
                </c-grid-item>
            </template>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, watch } from "vue";
import { Database } from "vuebase";
import { Order } from "../main";

export default {
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const orders = database?.collection<Order>("orders").documents();

        watch(
            () => orders,
            () => {
                orders?.sort((a, b) => {
                    return (b.data?.datePlaced?.seconds ?? 0) - (a.data?.datePlaced?.seconds ?? 0);
                });
            },
            { deep: true },
        );

        return {
            orders,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~coho-ui/src/styles/variables.scss";

.order {
    flex-wrap: nowrap;

    .grid-item {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .status {
        width: 10px;
        height: 40px;
    }

    &.pending {
        background-color: rgba($color-light, 0.2);
        color: rgba($color-dark, 0.5);

        .status {
            background-color: $color-light;
        }
    }

    &.cancelled {
        background-color: rgba($color-red, 0.2);
        text-decoration: line-through;
        text-decoration-color: $color-red;

        .status {
            background-color: $color-red;
        }
    }

    &.processing {
        background-color: rgba($color-yellow, 0.2);

        .status {
            background-color: $color-yellow;
        }
    }

    &.completed {
        background-color: rgba($color-green, 0.2);

        .status {
            background-color: $color-green;
        }
    }
}
</style>
