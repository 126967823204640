
import { inject, watch } from "vue";
import { Database } from "vuebase";
import { Order } from "../main";

export default {
    name: "EditProducts",
    setup() {
        const database = inject(Database.InjectionKey);
        const orders = database?.collection<Order>("orders").documents();

        watch(
            () => orders,
            () => {
                orders?.sort((a, b) => {
                    return (b.data?.datePlaced?.seconds ?? 0) - (a.data?.datePlaced?.seconds ?? 0);
                });
            },
            { deep: true },
        );

        return {
            orders,
        };
    },
};
